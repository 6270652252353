import React from "react";
import { Link } from "gatsby";

import CloseIcon from "./closeIcon";
import MobileMenyIcon from "./mobileMenyIcon";

export default function MobileNav() {
  const [mobileNav, setMobileNav] = React.useState(false);

  const handleOpen = () => {
    setTimeout(() => {
      document.body.classList.add("overflow-hidden");
      setMobileNav(true);
    }, 200);
  };

  const handleClose = () => {
    setTimeout(() => {
      document.body.classList.remove("overflow-hidden");
      setMobileNav(false);
    }, 200);
  };

  return (
    <section className="flex md:hidden">
      <MobileMenyIcon onClick={handleOpen} />
      {mobileNav && (
        <nav className="text-zinc-500 fixed top-0 left-0 bottom-0 right-0 bg-white flex items-center pt-32 flex-col z-20">
          <Link
            to="/"
            className="my-1 hover:text-black transition-colors"
            onClick={handleClose}
          >
            Strona główna
          </Link>
          <Link
            to="/oferta"
            className="my-1 hover:text-black transition-colors"
            onClick={handleClose}
          >
            Nasza oferta
          </Link>
          <Link
            to="/referencje"
            className="my-1 hover:text-black transition-colors"
            onClick={handleClose}
          >
            Referencje
          </Link>
          <Link
            to="/kontakt"
            className="my-1 hover:text-black transition-colors"
            onClick={handleClose}
          >
            Kontakt
          </Link>
          <CloseIcon className="absolute top-4 right-4" onClick={handleClose} />
        </nav>
      )}
    </section>
  );
}
