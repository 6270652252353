import React from "react";
import { Link } from "gatsby";

export default function DesktopNav() {
  return (
    <nav className="text-zinc-500 hidden md:flex">
      <Link to="/" className="mx-2 hover:text-black transition-colors">
        Strona główna
      </Link>
      <Link to="/oferta" className="mx-2 hover:text-black transition-colors">
        Nasza oferta
      </Link>
      <Link
        to="/referencje"
        className="mx-2 hover:text-black transition-colors"
      >
        Referencje
      </Link>
      <Link to="/kontakt" className="mx-2 hover:text-black transition-colors">
        Kontakt
      </Link>
    </nav>
  );
}
