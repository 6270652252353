import React from "react";

export default function Reference({ data, onClick, refId }) {
  return (
    <div className="mb-2">
      <div
        onClick={onClick(data.originalId)}
        className="border-2 p-2 cursor-pointer hover:bg-gray-50 font-bold"
      >
        {data.title}
      </div>
      {data.originalId === refId && (
        <div
          className="py-4 px-6 bg-gray-50"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      )}
    </div>
  );
}
