import React from "react";
import { Link } from "gatsby";

export default function Footer() {
  return (
    <footer className="text-center text-zinc-500 text-sm px-4 py-2">
      Copyright ©{" "}
      <Link
        to="/"
        className="hover:underline hover:text-black transition-colors"
      >
        olgacieslik.pl
      </Link>{" "}
      | Wszelkie Prawa Zastrzeżone
    </footer>
  );
}
