import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Container from "../components/container";
import Reference from "../components/reference";

export default function ReferencePage({ data: { reference } }) {
  const allReference = (reference && reference.nodes) || [];
  const [refId, setRefId] = React.useState(null);

  const handleClick = (id) => () => {
    if (id === refId) {
      setRefId(null);
      return;
    }
    setRefId(id);
  };

  return (
    <Layout>
      <Container>
        <h1 className="text-2xl font-bold mb-2">Referencje</h1>
        {allReference.map((ref) => (
          <Reference
            key={ref.originalId}
            data={ref}
            refId={refId}
            onClick={handleClick}
          />
        ))}
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }

    reference: allDatoCmsReference {
      nodes {
        title
        originalId
        description
      }
    }
  }
`;

export function Head() {
  return (
    <>
      <title>Olga Cieślik Referencje</title>
      <meta
        name="description"
        content="Referencje - Olga Cieślik Profesjonalny opiekun zwierząt"
      />
    </>
  );
}
