import React from "react";

import Main from "./main";
import Header from "./header";
import Footer from "./footer";

const layout = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default layout;
