import React from "react";
import { Link } from "gatsby";

import DesktopNav from "./DesktopNav";
import MobileNav from "./mobileNav";

export default function Header() {
  return (
    <header className="text-zinc-500 text-md shadow-md sticky top-0 z-50 bg-white  px-4">
      <section className="container m-auto max-w-screen-lg flex justify-between items-center min-h-[57px]">
        <Link to="/">
          <h1 className="hover:text-black transition-colors">
            <span className="flex -mb-1.5">Olga Cieślik</span>
            <span className="text-xs">Profesjonalny opiekun zwierząt</span>
          </h1>
        </Link>
        <DesktopNav />
        <MobileNav />
      </section>
    </header>
  );
}
